export const getMenuAdmin = [
  {
    category: true,
    title: 'Main Menu',
  },
  {
    title: 'Dashboard',
    key: 'Dashboard',
    icon: 'fe fe-bookmark',
    url: '/admin/dashboard',
  },
  {
    title: 'Teacher',
    key: 'Teacher',
    icon: 'fe fe-bookmark',
    url: '/admin/teacher',
  },
  {
    title: 'Class',
    key: 'Class',
    icon: 'fe fe-bookmark',
    url: '/admin/class',
  },
  {
    title: 'Student',
    key: 'Student',
    icon: 'fe fe-bookmark',
    url: '/admin/student',
    status: 'Walikelas',
  },
]
