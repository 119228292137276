<template>
  <a-menu v-model="currentMenu" class="topbarTeacher" mode="horizontal">
    <a-menu-item key="Dashboard Teacher" class="subMenuDetail">
      <router-link
        :target="currentRouteIsDetailSchedule && isLive? '_blank' : '_self'"
        :to="{ name : 'Dashboard Teacher'}"
      >
        <a-icon type="home" />Dashboard
      </router-link>
    </a-menu-item>
    <a-menu-item key="Schedule Teacher" class="subMenuDetail">
      <router-link
        :target="currentRouteIsDetailSchedule && isLive? '_blank' : '_self'"
        :to="{ name : 'Schedule Teacher'}"
      >
        <a-icon type="calendar" />Schedule
      </router-link>
    </a-menu-item>
    <a-menu-item key="Planning Teacher" class="subMenuDetail">
      <router-link
        :target="currentRouteIsDetailSchedule && isLive? '_blank' : '_self'"
        :to="{ name : 'Planning Teacher'}"
      >
        <a-icon type="appstore" />Planning
      </router-link>
    </a-menu-item>
    <!-- <a-menu-item key="Assignments Teacher" class="subMenuDetail">
      <router-link
        :target="currentRouteIsDetailSchedule && isLive? '_blank' : '_self'"
        :to="{ name : 'Assignments Teacher'}"
      >
        <a-icon type="inbox" />Assignments
      </router-link>
    </a-menu-item>-->
    <a-sub-menu class="submenu">
      <span slot="title" class="submenu-title-wrapper">
        <a-icon type="solution" />Assesments
      </span>
      <a-menu-item key="Assignments Teacher" class="subMenuDetail">
        <router-link
          :target="currentRouteIsDetailSchedule && isLive? '_blank' : '_self'"
          :to="{ name : 'Assignments Teacher'}"
        >
          <a-icon type="inbox" />Assignments
        </router-link>
      </a-menu-item>
      <a-menu-item key="Reports Teacher" class="subMenuDetail">
        <router-link
          :target="currentRouteIsDetailSchedule && isLive? '_blank' : '_self'"
          :to="{ name : 'Reports Teacher'}"
        >
          <a-icon type="reconciliation" />Reports
        </router-link>
      </a-menu-item>
    </a-sub-menu>
    <a-menu-item v-if="role.includes('waliKelas')" key="Homeroom Teacher" class="subMenuDetail">
      <!-- <a-menu-item key="Homeroom" class="subMenuDetail"> -->
      <router-link
        :target="currentRouteIsDetailSchedule && isLive? '_blank' : '_self'"
        :to="{ name : 'Homeroom Teacher'}"
      >
        <!-- <a-icon type="home" />Homeroom -->
        <i class="fas fa-child mr-2"></i>Homeroom
      </router-link>
    </a-menu-item>
    <a-menu-item key="Cbt Teacher" class="subMenuDetail">
      <router-link
        :target="currentRouteIsDetailSchedule && isLive? '_blank' : '_self'"
        :to="{ name : 'Cbt Teacher'}"
      >
        <a-icon type="file-protect" />CBT
      </router-link>
    </a-menu-item>
    <a-menu-item v-if="role.includes('guruAdmin')" key="Admin" class="subMenuDetail">
      <router-link
        :target="currentRouteIsDetailSchedule && isLive? '_blank' : '_self'"
        :to="{ name : 'Assignments Teacher'}"
      >
        <a-icon type="key" />Admin
      </router-link>
    </a-menu-item>
  </a-menu>
</template>
<script>
export default {
  created() {
    if (this.$router.history.current.name.split(' ').includes('Schedule')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Schedule Teacher'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Planning')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Planning Teacher'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Assignments')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Assignments Teacher'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Homeroom')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Homeroom Teacher'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Cbt')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Cbt Teacher'],
      })
    } else {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: [this.$router.history.current.name],
      })
    }
  },
  computed: {
    currentMenu: {
      get: function() {
        return this.$store.state.menu.currentMenu
      },
      set: function(value) {
        if (this.isLive) {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Schedule Teacher'],
          })
        } else {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: value,
          })
        }
      },
    },
    isLive() {
      return this.$store.state.live.isLive
    },
    currentRouteIsDetailSchedule() {
      return this.$route.name.match(/Detail Schedule/gi) !== null
    },
    role() {
      return this.$store.state.user.role
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
