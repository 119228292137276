<template>
  <a-menu v-model="currentMenu" mode="horizontal">
    <!-- <a-menu-item key="Dasbor Admin HR" class="subMenuDetail">
      <router-link :to="{ name : 'Dasbor Admin HR'}">
        <a-icon type="home" />Dasbor
      </router-link>
    </a-menu-item> -->
    <a-menu-item key="Kehadiran Admin HR" class="subMenuDetail">
      <router-link :to="{ name : 'Kehadiran Admin HR'}">
        <a-icon type="audit" />Kehadiran
      </router-link>
    </a-menu-item>
    <a-menu-item key="Master Data Admin HR" class="subMenuDetail">
      <router-link :to="{ name : 'Master Data Admin HR'}">
        <a-icon type="folder-open" />Master Data
      </router-link>
    </a-menu-item>
  </a-menu>
</template>
<script>
export default {
  created() {
    if (this.$router.history.current.name.split(' ').includes('Dasbor')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Dasbor Admin HR'],
      })
    } else {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: [this.$router.history.current.name],
      })
    }
  },
  computed: {
    currentMenu: {
      get: function() {
        return this.$store.state.menu.currentMenu
      },
      set: function(value) {
        this.$store.commit('menu/SET_STATE', {
          currentMenu: value,
        })
      },
    },
    role() {
      return this.$store.state.user.role
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
