export const getMenuMurid = [
  {
    category: true,
    title: 'Main Menu',
  },
  {
    title: 'Dashboard',
    key: 'Dashboard',
    icon: 'fe fe-bookmark',
    url: '/student/dashboard',
  },
  {
    title: 'Schedule',
    key: 'Schedule',
    icon: 'fe fe-bookmark',
    url: '/student/schedule',
  },
  {
    title: 'Class',
    key: 'Class',
    icon: 'fe fe-bookmark',
    url: '/student/class',
  },
  {
    title: 'CBT',
    key: 'CBT',
    icon: 'fe fe-bookmark',
    url: '/student/cbt',
  },
]
