export const getMenuGuruMapel = [
  {
    category: true,
    title: 'Main Menu',
  },
  {
    title: 'Dashboard',
    key: 'Dashboard',
    icon: 'fe fe-bookmark',
    url: '/teacher/dashboard',
  },
  {
    title: 'Schedule',
    key: 'Schedule',
    icon: 'fe fe-bookmark',
    url: '/teacher/schedule',
  },
  {
    title: 'Planning',
    key: 'Planning',
    icon: 'fe fe-bookmark',
    url: '/teacher/planning',
  },
  {
    title: 'Assesments',
    key: 'Assesments',
    icon: 'fe fe-image',
    children: [
      {
        title: 'Assignments',
        key: 'Assignments',
        url: '/teacher/assignments',
      },
      {
        title: 'Reports',
        key: 'Reports',
        url: '/teacher/reports',
      },
    ],
  },
  {
    title: 'CBT',
    key: 'CBT',
    icon: 'fe fe-bookmark',
    url: '/teacher/cbt',
  },
]
