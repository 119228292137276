<template>
  <a-menu v-model="currentMenu" mode="horizontal">
    <a-menu-item key="Dashboard Admin" class="subMenuDetail">
      <router-link :to="{ name : 'Dashboard Admin'}">
        <a-icon type="home" />Dashboard
      </router-link>
    </a-menu-item>
    <a-menu-item key="Teacher Admin" class="subMenuDetail">
      <router-link :to="{ name : 'Teacher Admin'}">
        <a-icon type="audit" />Teachers
      </router-link>
    </a-menu-item>
    <a-menu-item key="Class Admin" class="subMenuDetail">
      <router-link :to="{ name : 'Class Admin'}">
        <a-icon type="bank" />Classes
      </router-link>
    </a-menu-item>
    <a-menu-item key="Student Admin" class="subMenuDetail">
      <router-link :to="{ name : 'Student Admin'}">
        <a-icon type="team" />Students
      </router-link>
    </a-menu-item>
  </a-menu>
</template>
<script>
export default {
  created() {
    if (this.$router.history.current.name.split(' ').includes('Teacher')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Teacher Admin'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Student')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Student Admin'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Class')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Class Admin'],
      })
    } else {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: [this.$router.history.current.name],
      })
    }
  },
  computed: {
    currentMenu: {
      get: function() {
        return this.$store.state.menu.currentMenu
      },
      set: function(value) {
        this.$store.commit('menu/SET_STATE', {
          currentMenu: value,
        })
      },
    },
    role() {
      return this.$store.state.user.role
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
